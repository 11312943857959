import React from 'react';
import { stickyContent } from '../../../consts/stickyContent';
import MobileSticky from './Mobile';
import DesktopSticky from './Desktop';
import useDeviceDetect from '../../../hooks/useDeviceDetect';

function Sticky(){
  const { isMobile, isTablet } = useDeviceDetect();

  return(
    <>
      {
        isMobile || isTablet
          ?
          stickyContent.map((item, index) => (
            <MobileSticky
              width={item.width}
              className={index !== 0 ? 'mt-20 md:mt-130' : ''}
              title={item.title}
              texts={item.texts}
              images={item.images}
              alt={item.alt}
              timeoutImage={item.timeoutImage}
              count={index}
              key={index}
            />
          ))
          :
          stickyContent?.map((item,index) => (
            <DesktopSticky
              colSpan={item.colSpan}
              index={index}
              maxWidth={item.maxWidth}
              title={item.title}
              texts={item.texts}
              images={item.images}
              alt={item.alt}
              timeoutImage={item.timeoutImage}
              count={index}
              key={index}
            />
          ))
      }
    </>
  );
}

export default Sticky;