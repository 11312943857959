import PropTypes from 'prop-types';
import H2 from '../Headings/H2';
import parse from 'html-react-parser';

function EpilogueItemWithoutMediaFile({ paragraphs, title }){
  return(
    <div className='col-span-full xl:col-start-3 xl:col-end-11'>
      <H2 className='text-blue-750 text-center mt-24 mb-16 md:mb-20' name={title} />
      {
        paragraphs.map((text, index) => (
          <p className='text_s_light text-black-750 xl:font-primary-light xl:text-lg xl:tracking-normal mt-6 xl:mt-8' key={index}>{parse(text)}</p>
        ))
      }
    </div>
  );
}

EpilogueItemWithoutMediaFile.propTypes = {
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
};

export default EpilogueItemWithoutMediaFile;