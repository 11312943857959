import React from 'react';
import './styles/globals.scss';
import Header from './components/Layout/Header';
import Prologue from './components/sections/Prologue';
import Epilogue from './components/sections/Epilogue';
import Sticky from './components/shared/Sticky';
import Footer from './components/Layout/Footer';

function App() {
  return (
    <div className='App'>
      <Header />
      <Prologue />
      <Sticky />
      <Epilogue />
      <Footer />
    </div>
  );
}

export default App;