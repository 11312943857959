import React from 'react';
import PropTypes from 'prop-types';

function H2({ name, className }){
  return(
    <h2 className={`heading_2 ${className}`}>{name}</h2>
  );
}

H2.defaultProps = {
  className: ''
};

H2.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default H2;