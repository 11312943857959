import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import parse from 'html-react-parser';
import H2 from '../../Headings/H2';
import Container from '../../Container';

function DesktopSticky(props){
  const {
    className,
    texts,
    images,
    alt,
    title,
    timeoutImage,
    colSpan,
    maxWidth,
    count
  } = props;

  const [viewport, setViewport] = useState(0);

  const imageRef = useRef(null);

  const [height, setHeight] = useState(0);

  const [showTimeoutImage, setShowTimeoutImage] = useState(false);

  const sectionRef = useRef(null);

  const textRef = useRef([]);

  const scrollHandle = useCallback(() => {
    for(let i = 0; i < textRef?.current?.length; i++){
      if(i === 0 && textRef?.current[0].getBoundingClientRect().y >= 75){
        setViewport(0);
        break;
      } else if(textRef?.current[i].getBoundingClientRect().y < 75){
        setViewport(i + 1);
      }
    }
  },[]);

  useEffect(() => {
    if(viewport === 1 && timeoutImage === 2 && textRef?.current[0].getBoundingClientRect().y < 75){
      setTimeout(() => {
        if(viewport === 1 && timeoutImage === 2 && textRef?.current[0].getBoundingClientRect().y < 75){
          setShowTimeoutImage(true);
        }

      }, 1000);
    } else if(viewport === 2 && timeoutImage === 3 && textRef?.current[1].getBoundingClientRect().y < 75){
      setTimeout(() => {
        if(viewport === 2 && timeoutImage === 3 && textRef?.current[1].getBoundingClientRect().y < 75) {
          setShowTimeoutImage(true);
        }
      }, 1000);
    } else if(viewport < 2){
      setShowTimeoutImage(false);
    }
  }, [viewport]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    window.addEventListener('scroll', scrollHandle);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', scrollHandle);
      window.removeEventListener('resize', onResize);
    };
  },[]);

  const onImgLoad = (e) => {
    if(e.target) {
      setHeight(e?.target?.offsetHeight);
    } else {
      setHeight(e);
    }
  };

  const onResize = () => {
    onImgLoad(imageRef?.current?.offsetHeight);
  };

  return (
    <section className={className} ref={sectionRef}>
      <Container>
        <div className={`col-span-5 ${count !== 0 ? 'mt-200' : 'mt-36'} ${styles.wrapper}`}>
          <H2 name={title} className='text-blue-750 pb-14'/>
          {
            texts?.map((text,index) => (
              <div className='bg-white p-25 shadow-textBlockShadow' key={index} ref={el => textRef.current[index] = el}>
                <p className='text_m_light text-black-650'>{parse(text)}</p>
              </div>
            ))
          }
        </div>
        <div className={`${styles.stickyImage} mx-auto relative`}  style={{ height: height, maxWidth: maxWidth && maxWidth, gridColumnStart: colSpan && colSpan[0], gridColumnEnd: colSpan && colSpan[1] }}>
          <img
            ref={imageRef}
            src={images[0]}
            onLoad={(e) => onImgLoad(e)}
            alt={alt}
            className='object-cover mb-10'
          />
          <img
            src={images[1]}
            alt={alt}
            className={`object-cover absolute top-0 left-0 ${viewport === 1 ? 'animate-showImage' : viewport > 1 && images[2] ? 'animate-hideImage' : ''} ${viewport !== 0 ? 'opacity-1' : 'opacity-0'}`}
          />
          {images[2]
                &&
                <img
                  src={images[2]}
                  alt={alt}
                  className={`object-cover absolute top-0 left-0 ${viewport === 2 ? 'animate-showImage' : viewport !== 3 && viewport !== 4 ? 'animate-hideImage' : ''} ${viewport !== 1 ? 'opacity-1' : 'opacity-0'}`}
                />
          }
          {
            showTimeoutImage
              ?
              <img
                src={images[timeoutImage]}
                alt={alt}
                className={`object-cover absolute top-0 left-0 animate-showImage ${viewport !== 0 ? 'opacity-1' : 'opacity-0'}`}
              />
              :
              <img
                src={images[timeoutImage]}
                alt={alt}
                className={`object-cover absolute top-0 left-0 animate-hideImage ${viewport !== 0 ? 'opacity-1' : 'opacity-0'}`}
              />
          }
        </div>
      </Container>
    </section>
  );
}

DesktopSticky.defaultProps = {
  className: '',
  timeoutImage: 0,
};

DesktopSticky.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  texts: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  alt: PropTypes.string.isRequired,
  timeoutImage: PropTypes.number,
  maxWidth: PropTypes.number.isRequired,
  colSpan: PropTypes.arrayOf(PropTypes.number).isRequired,
  count: PropTypes.number.isRequired
};

export default DesktopSticky;