import React from 'react';
import Container from '../../shared/Container';
import H2 from '../../shared/Headings/H2';
import { epilogueContentWithMediaFile, epilogueContentWithoutMediaFile, epilogueTitle } from '../../../consts/epilogueContent';
import EpilogueItemWithMediaFile from '../../shared/EpilogueItemWithMediaFile';
import EpilogueItemWithoutMediaFile from '../../shared/EpilogueItemWithoutMediaFile';

function Epilogue() {
  return(
    <section className='mb-32 lg:mb-36'>
      <Container>
        <H2 name={epilogueTitle} className='mt-36 col-span-full text-blue-750 text-center xl:col-span-8 xl:col-start-3 xl:col-end-11' />
        {
          epilogueContentWithMediaFile.map((item, index) => (
            <EpilogueItemWithMediaFile key={index} className={index === 5 ? 'mb-12 md:mb-8' : ''} paragraphs={item.paragraphs} images={item.images} />
          ))
        }
        {
          epilogueContentWithoutMediaFile.map((item, index) => (
            <EpilogueItemWithoutMediaFile key={index} paragraphs={item.paragraphs} title={item.title} />
          ))
        }
        <div className='col-span-full xl:col-start-3 xl:col-end-11'>
          <p className='mt-32 lg:mt-28 text-black-750 text-xl leading-30 tracking-normal'>Հեղինակներ՝</p>
          <p className='mt-8 text_m_light'>Մովսես Խաչատրյան</p>
          <p className='mt-3 text_m_light'>Ռուզաննա Աբրահամյան</p>
          <p className='mt-8 text-black-750 text-xl leading-30 tracking-normal'>Համակարգող՝</p>
          <p className='mt-8 text_m_light'>Անի Հովհաննիսյան</p>
        </div>
      </Container>
    </section>
  );
}

export default Epilogue;