export const epilogueContentWithMediaFile = [
  {
    paragraphs: [
      '<span style="fontFamily:primary-semibold">«Օլիվեստա» ՍՊԸ-ի</span> բաժնեմասի կեսը մինչև 2020-ը պատկանել է նախկին վարչապետ Կարեն Կարապետյանի եղբորը՝ Վլադիմիր Կարապետյանին։',
      'Այժմ գազալցակայանի միանձնյա սեփականատերը Արտաշատ քաղաքի բնակիչ Արթուր Պետրոսյանն է։ «Օլիվեստա» ՍՊԸ-ի տնօրենը Մխչյան գյուղի բնակիչ Արման Հարությունյանն է, որը նաև ղեկավարում  է «Արգ-լև գազ» ՍՊԸ-ն։ Դրա 50% բաժնեմասը պատկանում է նախկին վարչապետ Հովիկ Աբրահամյանի որդուն՝ Արգամ Աբրահամյանին։ «Օլիվեստա» ՍՊԸ-ն գազալցակայան է շահագործում Ա. Բաբաջանյան 60/1 հասցեում։',
      'Գազալցակայանում կուտակիչներից մինչև լիցքավորման սարքերը սահմանված 20մ հեռավորության փոխարեն 16մ է, կուտակիչներից մինչև գազալցակայանի տարածքում գտնվող այլ նշանակություն ունեցող շենքերը՝ սահմանված 30մ հեռավորության փոխարեն 28մ, կուտակիչներից մինչև գազալցակայանի ցանկապատը սահմանված 5մ հեռավորությունը պահպանված չէ։',
      'Գազալցակայանից խախտումների մասին մեր պարզաբանման խնդրանքին այդպես էլ չարձագանքեցին։'
    ],
    images: [
      {
        src: {
          primary: './images/Epilogue/olivesta.webp',
          secondary: './images/Epilogue/olivesta.png'
        },
        alt: 'olivesta'
      }
    ]
  },
  {
    paragraphs: [
      '<span style="fontFamily:primary-semibold">«Պոդո առաջին» ՍՊԸ-ն</span> 2005-2017 թվականներին պատկանել է ԱԺ նախկին պատգամավոր, Վրաստանում Հայաստանի նախկին դեսպան <a href="http://despan.am/hy/republic-of-armenia-ambassadors/51/" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Ռուբեն Սադոյանին</a>։ Ընկերության ներկայիս սեփականատերն Ալբերտ Խաչատրյանն է։ Արին-Բերդի 3-րդ նրբ․ 7/2 հասցեում է ընկերությունը գազալցակայան շահագործում։',
      'Ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքերի ու շինությունների միջև հեռավորությունը 29մ է սահմանված 50մ-ի փոխարեն։ Սպասասրահից մինչև ճնշակայան և մինչև գազի կուտակիչների տեղամաս հեռավորությունները նույնպես չեն համապատասխանում սահմանված քաղաքաշինական միջտարածությունների նորմերին։'
    ],
    images: [
      {
        src: {
          primary: './images/Epilogue/podo_1.webp',
          secondary: './images/Epilogue/podo_1.png'
        },
        alt: 'podo',
        subtitle: 'Սպասարահից մինչև լիցքավորման սարքերը 18մ է 20-ի փոխարեն։'
      },
      {
        src: {
          primary: './images/Epilogue/podo_2.webp',
          secondary: './images/Epilogue/podo_2.png'
        },
        alt: 'podo',
        subtitle: 'Լցակետերն իրարից պետք է բաժանված լինեն բետոնե պատով, սակայն այս գազալցակայանում լցակետերի միջև բետոնե պատի կեսը կա, մյուս կեսի փոխարեն դրված է լիցքավորման սարքը։'
      },
      {
        src: {
          primary: './images/Epilogue/podo_3.webp',
          secondary: './images/Epilogue/podo_3.png'
        },
        alt: 'podo',
        subtitle: 'Խախտումների վերաբերյալ ընկերությունից նախ համաձայնեցին պարզաբանում տալ, ապա հրաժարվեցին։'
      }
    ]
  },
  {
    paragraphs: [
      '<span style="fontFamily:primary-semibold">«Էլսի Ստիլ» ՍՊԸ-</span>ում մինչև 2020-ը 30% բաժնեմաս է ունեցել նախկին քաղաքապետ, ներկայիս ԱԺ պատգամվոր Տարոն Մարգարյանի հորաքույրը՝ Անահիտ Մարգարյանը։ Այս պահին ընկերության 70% բաժնեմասը պատկանում է Նարինե Գաբրիելյանին: Նա «Գազպրոմ Արմենիա» ՍՊԸ-ի նախկին տնօրեն Նիկոլայ Գաբրիելյանի (Գազի Կոլյա) դուստրն է և նախկին գործարար Արա Վարդանյանի (Ճվճվ Արո) այրին: Ընկերությունում 20% և 10 % բաժնեմասեր ունեն համապատասխանաբար Արտակ Լևոնի Գասպարյանը և Կարեն Հարությունի Մուրադյանը: Գասպարյանը 2015-ին Գեղարքունիքի մարզում ձեռք էր բերել գեներալ Հայկազ Բաղմանյանի որդու գազալցակայանը, ապա 2018-ին վաճառել այն։ «Էլսի Ստիլ» ՍՊԸ-ն Միկոյան 103 հասցեում շահագործում է գազալցակայան։',
      'Արտակարգ իրավիճակների նախարարության և ՔՏՀԱ տեսչական մարմնի հարևանությամբ կառուցված այս գազալցակայանում էական խախտում ենք հայտնաբերել։ Այստեղ պահպանված չէ ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքերն ու շինությունները սահմանված 50մ հեռավորությունը։ Սակայն արբանյակից արված լուսանկարներից պարզ դարձավ, որ գազալցակայանի հարևանությամբ գտնվող շենքերը կառուցվել են գազալցակայանի շահագործումից հետո։'
    ],
    images: [
      {
        src: {
          primary: './images/Epilogue/elsi_1.webp',
          secondary: './images/Epilogue/elsi_1.png'
        },
        alt: 'elsi',
        subtitle: 'Լցակետերն իրարից պետք է բաժանված լինեն բետոնե պատով, և մի լցակետում լիցքավորվող մեքենաների թույլատրելի քանակը մեկն է։ Մինչդեռ այս գազալցակայանում մեկ լցակետում լիցքավորվում է երկու մեքենա, իսկ նրանց միջև բացակայում է բետոնե պատը։'
      },
      {
        src: {
          primary: './images/Epilogue/elsi_2.webp',
          secondary: './images/Epilogue/elsi_2.png'
        },
        alt: 'elsi',
        subtitle: 'Գազալցակայանի տնօրինությունից պարզաբանում ստանալ չհաջողվեց, թեև մեր հեռախոսահամարը փոխանցել էինք աշխատակիցներին։'
      }
    ]
  },
  {
    paragraphs: [
      '<span style="fontFamily:primary-semibold">«Գիմաս» ՍՊԸ-ն</span> մինչև 2013-ը պատկանել է Գերագույն խորհրդի նախկին պատգամավոր <a href="http://www.parliament.am/deputies.php?sel=details&ID=626&lang=arm" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Մեխակ Գաբրիելյանին</a> և նրա որդիներին։ Այժմ ընկերության միանձնյա սեփականատերը ՌԴ քաղաքացի Եվա Բախտիկյանն է։ Ընկերությունը գազալցակայան է շահագործում՝ Շիրակի 1/5 հասցեում։ Այս գազալցակայանում սպասասրահի և լիցքավորման սարքերի միջև հեռավորություըն 18մ է սահմանված 20մ-ի փոխարեն։'
    ],
    images: [
      {
        src: {
          primary: './images/Epilogue/gimas.webp',
          secondary: './images/Epilogue/gimas.png'
        },
        alt: 'gimas',
        subtitle: 'Ընկերության տնօրենը հրաժարվեց խախտման մասին մեր հարցին պատասխանել։'
      }
    ]
  },
  {
    paragraphs: [
      '<span style="fontFamily:primary-semibold">«Արտ-Թամ» ՍՊԸ-ի</span> բաժնեմասերի 50%-ը մինչև 2021-ը պատկանել է նախկին վարչապետ Հովիկ Աբրահամյանի դստերը՝ Աննա Աբրահամյանին։ Այժմ ընկերությունը պատկանում է Վահան Խաչատրյանին և նրա որդուն՝ Կարեն Խաչատրյանին։ Ընկերության գազալցակայանը գտնվում է Արտաշատի խճ. 106 հասցեում։ Այստեղ սպասասրահից մինչև լիցքավորման սարքերը 10մ հեռավորություն է նախատեսված 20մ-ի փոխարեն։'
    ],
    images: [
      {
        src: {
          primary: './images/Epilogue/art_tam.webp',
          secondary: './images/Epilogue/art_tam.png'
        },
        alt: 'art_tam',
        subtitle: 'Գազալցակայանի տնօրենը հայտնեց, որ Երևանի քաղաքապետարան են դիմել՝ խախտումը վերացնելու համար շինթույլտվություն ստանալու։ Ըստ տնօրենի՝ կես տարուց ավելի սպասում են փաստաթղթերի հաստատմանը'
      }
    ]
  },
  {
    paragraphs: [
      '<span style="fontFamily:primary-semibold">«Ռոբել»</span> արտադրական կոոպերատիվը հիմնադրել է նախկին վարչապետ (1992-1993) Խոսրով Հարությունյանի որդին՝ <a href="https://hy.wikipedia.org/wiki/%D5%8C%D5%B8%D5%A2%D5%A5%D6%80%D5%BF_%D5%80%D5%A1%D6%80%D5%B8%D6%82%D5%A9%D5%B5%D5%B8%D6%82%D5%B6%D5%B5%D5%A1%D5%B6_(%D5%BA%D5%A5%D5%BF%D5%A1%D5%AF%D5%A1%D5%B6_%D5%A3%D5%B8%D6%80%D5%AE%D5%AB%D5%B9)" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Ռոբերտ Հարությունյանը</a>, որը արտաքին գործերի նախկին փոխնախարար է (2016-2018)։ Ընկերությունը 2020 թվականի օգոստոսին ձեռք է բերել Աշոտ Հակոբյանը։ Այժմ բաժնետոմսերի 95%-ը պատկանում է նրան, իսկ 5%-ը՝ կնոջը՝ Տաթև Հովհաննիսյանին։ Աշոտ Հակոբյանը <a href="https://hetq.am/hy/article/133686" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">«Քարավան» ՍՊԸ</a>-ի սեփականատեր Հակոբ Հակոբյանի որդին է։ «Քարավան» ՍՊԸ-ն շինարարական կազմակերպություն է, գրանցված է ԼՂՀ-ում, միակ սեփականատերը գործարար Հակոբ Հակոբյանն է։ Նա ծնունդով Վայոց Ձորի մարզից է, սակայն 1995 թվականից տեղափոխվել է Արցախ և գործարարությամբ է զբաղվում։ Սերտ կապեր ունի Արցախի Հանրապետության նախագահ Արայիկ Հարությունյանի հետ։ «Ռոբել» արտադրական կոոպերատիվը գազալցակայան է շահագործում Լուկաշինի (Սիլիկյան հին) պողոտա 5 հասցեում։'
    ]
  },
];

export const epilogueContentWithoutMediaFile = [
  {
    title: 'Ինչո՞ւ ԱԳԼՃԿ-ներում ստուգումներ չեն անցկացվում',
    paragraphs: [
      'Քաղաքաշինության, տեխնիկական և հրդեհային անվտանգության տեսչական մարմինը 2020-2021 թվականներին Երևանում ԱԳԼՃԿ-ների որևէ ստուգում չի իրականացրել։ Տեսչական մարմնի տեխնիկական և հրդեհային անվտանգության վարչության պետ Ռուբեն Խաչունցի պնդմամբ՝ ստուգումները ժամանակ և ռեսուրս են պահանջում։',
      '«Եթե մենք ընդունենք, որ ՀՀ-ում գործում է մոտավորապես 35 հազար օբյեկտ, և ընդունենք, որ դրա 20%-ը բարձր ռիսկայնության է (այսինքն՝ 7000 օբյեկտ), «Ստուգումների կազմակերպման և անցկացման մասին» ՀՀ օրենքով այդ 7000-ը հնարավորություն է տրվում ստուգել տարին 1 անգամ։ Մենք տարեկան կարող ենք ստուգել 400-600 օբյեկտ։ Անհնար է 7000 օբյեկտի ստուգումը տարվա ընթացքում՝ ՏՄ համապատասխան ռեսուրսի բացակայության պատճառով»,- մեզ հետ զրույցում նշեց ՔՏՀԱ տեսչական մարմնի տեխնիկական և հրդեհային անվտանգության վարչության պետ Ռուբեն Խաչունցը։'
    ]
  },
  {
    title: 'Ինչո՞ւ են դրական եզրակացություններ տրվել քաղաքաշինական խախտումներով աշխատող գազալցակայաններին',
    paragraphs: [
      'Գազալցակայանները արտադրական վտանգավոր օբյեկտներ (ԱՎՕ) են, որոնք ենթակա են տեխնիկական անվտանգության փորձաքննության տարեկան առնվազն մեկ անգամ: Ըստ <a href="https://www.arlis.am/documentview.aspx?docID=65225" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45" xmlns="http://www.w3.org/1999/html">«Տեխնիկական անվտանգության ապահովման պետական կարգավորման մասին»</a> օրենքի՝ գազալցակայանը կամ դրանում օգտագործվող տեխնիկական միջոցները, շենքերն ու շինությունները պետք է համապատասխանեն տեխնիկական անվտանգության ոլորտի օրենսդրական պահանջներին։ Եթե գազալցակայանը սահմանված կարգով չի անցել տեխնիկական անվտանգության փորձաքննություն կամ չի գրանցվել ԱՎՕ ռեեստրում, ապա լիազոր մարմինն դրա շահագործումն արգելելու մասին կարգադրագիր է արձակում։ Կարգադրագիրը չկատարելու դեպքում նշանակվում է տուգանք՝ նվազագույն աշխատավարձի երկուհազարապատիկի չափով (2 մլն ՀՀ դրամ)։',
      'Արտադրական վտանգավոր օբյեկտների (ԱՎՕ) ռեեստրը վարում է Արտակարգ իրավիճակների նախարարությունը։ Նախարարությունից ստացել ենք 2020 և 2021 թվականներին Երևանի ԱԳԼՃԿ-ներում իրականացված տեխնիկական անվտանգության փորձաքննության եզրակացությունները՝ 113 էջ։',
      'Փորձագիտական մարմինը դրական եզրակացություն է տվել ակնհայտ խախտումներով աշխատող գազալցակայաններին: Նույնիսկ փորձագիտական եզրակացության մեջ առկա են լուսանկարներ, որոնցում նկատելի են խախտումները: Օրինակ՝ «Էլսի ստիլ» ՍՊԸ-ի փորձագիտական եզրակացության մեջ տեղադրված լուսանկարում երևում է, որ մի լցակետում կայանված է երկու մեքենա: Քաղաքաշինական նորմը սահմանում է, որ լցակետերը պետք է միմյանցից բաժանված լինեն բետոնե պատով, իսկ յուրաքանչյուր լցակետում մի մեքենա կարող է լիցքավորվել:',
      'Երբ այդ մասին խոսեցինք դրական եզրակացություն տված փորձագետի հետ, նա հրաժարվեց հարցազրույց տալ, սակայն նշեց, որ լիցքավորման մի կետում երկու մեքենա չի լիցքավորվում, աշխատակիցներն ուղղակի իրենց մեքենաներն են կայանում նույն կետում: Նույնիսկ եթե հավատանք դրան, լիցքավորման կետը չի կարող կայանատեղի ծառայել: Մեր պնդումներից հետո փորձագետը հավելեց, որ եթե այդպես լինի, բոլոր գազալցակայանների գործունեությունը պետք է արգելել, քանի որ բոլորն էլ կառուցվել են նախկինում՝ նորմերի հաստատումից առաջ:',
      '2021 թվականին ամենամյա փորձաքննություն չի անցել միայն «Արտ-Թամ» ՍՊԸ-ի գազալցակայանը, մնացած 22 գազալցակայաններն անցել են փորձաքննություն և ստացել դրական եզրակացություն։ Իսկ 2020 թվականին ամենամյա փորձաքննություն չեն անցել 14-ը՝ «Մուլտի–Լեոն» ՍՊԸ-ի, «Մուլտի Գազ» ՍՊԸ-ի, «Օլիվեստա» ՍՊԸ-ի, «Վ-Սանկ» ՍՊԸ-ի, «Պոդո առաջին» ՍՊԸ֊ի, «Խճաքար-Մուսա» ՍՊԸ-ի, «Արտ Թամ» ՍՊԸ-ի և «Ավտոստոպ» ՍՊԸ-ի գազալցակայանները։ Ավելին՝ Նուբարաշենի խճ․97/3 հասցեում գտնվող գազալցակայանը, որը համատեղությամբ շահագործում են «Մուլտի Գազ» և «Ավտոգազ» ՍՊԸ֊ները, 2021 թվականի սեպտեմբերի 30-ի դրությամբ գրանցված չի եղել ԱՎՕ ռեեստրում։ 2021 թվականի դեկտեմբերի 17-ին անցել է փորձաքննություն և ստացել դրական եզրակացություն։'
    ]
  },
  {
    title: 'Տեսչական մարմինը ներկայում բացառում է նորմերի խախտումներով գազալցակայանի նախագծի հաստատումը',
    paragraphs: [
      '«Բացառում եմ, որ մեր կողմից տրված դրական եզրակացությամբ կառուցվի ԱԳԼՃԿ ու այնտեղ լինի միջտարածությունների խախտում։ Ունենք շատ դեպքեր, որ մեզ ներկայացվում են նախագծեր, որոնցում հայտնաբերում ենք նախագծային խախտումներ։ Նախագծողը տեղեկացվում է հայտնաբերված խախտումների մասին, տրվում է բացասական եզրակացություն, նախագիծը վերադարձվում է լրամշակման, որից հետո նոր միայն այն կարող է կրկին ներկայացվել Տեսչական մարմին՝ համապատասխան դրական եզրակացություն ստանալու համար։ Հետագայում, թե ինչքանով է կառուցված օբյեկտը համապատասխանում նախագծային փաստաթղթերին, մենք պարզում ենք կամ օբյեկտի շահագործման ընդունման ընթացքում կամ ստուգման ընթացքում»,- նշում է ՔՏՀԱ տեսչական մարմնի տեխնիկական և հրդեհային անվտանգության վարչության պետ Ռուբեն Խաչունցը։',
      'Քաղաքաշինության կոմիտեի ճարտարապետության և քաղաքաշինության վարչության խորհրդական Մարտուն Գրիգորյանին խնդրեցինք ներկայացնել, թե ԱԳԼՃԿ-ների և ընդհանրապես 4-րդ աստիճանի բարձր ռիսկայնության օբյեկտների առնչությամբ ինչ գործառույթներ և վերահսկման ինչ իրավասություններ ունի Քաղաքաշինության կոմիտեն։ «Քաղաքաշինության կոմիտեն հիմնականում հանդիսանում է քաղաքաշինության ոլորտի քաղաքականության վարող, սահմանում է ոչ միայն չորրորդ աստիճանի, այլ առհասարակ ռիսկայնության աստիճանների հետ կապված բոլոր դասակարգումները՝  թե որ օբյեկտները ինչ, այսպես ասած, պատճառաբանությամբ են առաջին, երկրորդ, երրորդ կամ չորրորդ աստիճան ունեցող ռիսկայնության օբյեկտների կազմում։ Չորրորդի պարագայում հստակ նշված է, որ չորրորդ ռիսկայնության օբյեկտները ենթակա են պետական համալիր փորձաքննության, որը հատուկ նշվում է նաև համայնքների կողմից տրվող ճարտարապետահատակագծային առաջադրանքում»,- ասաց նա։',
      'Իսկ քաղաքաշինության կոմիտեի շինարարության և գիտատեխնիկական նորմավորման վարչության պետ Ռուզան Ադամյանը մեր հարցին, թե համալիր փորձաքննության ժամանակ արդյոք չի՞ դիտարկվում, որ կառուցապատողի կողմից կա քաղաքաշինական նորմի խախտում, պատախանեց. «Այդ նորմերի հստակ պահանջները քաղաքաշինական պարզ փորձաքննության ընթացքում են ստուգվում: Այսինքն, եթե փորձագիտական մարմինը, որը լիցենզավորված է, տալիս է դրական եզրակացություն, նրա եզրակացությունը հերքելը մեկ այլ լիցենզավորված փորձագիտական մարմնի գործառույթ կարող է լինել, բայց ոչ ավելին: Այսինքն, պետական մարմինը փորձագիտական եզրակացությունը հերքել չի կարող: Եթե կան կասկածներ, ուրեմն մեկ այլ լիցենզավորված կազմակերպություն պետք է դա անի»,- նշեց քաղաքաշինության կոմիտեի շինարարության և գիտատեխնիկական նորմավորման վարչության պետը։',
      'Ռուզան Ադամյանի խոսքով՝ համայնքի ղեկավարը մեծ պատասխանատվություն է կրում հողահատկացման փուլում, քանի որ կառուցապատողը շենքի նկատմամբ սեփականության իրավունք է ձեռք բերում, որը պաշտպանված է Սահմանադրությամբ։ ',
      'Երևանի քաղաքապետարանի աշխատակազմի ճարտարապետության և քաղաքաշինության վարչությունից էլ հակադարձում են, թե գազալցակայանների կառուցապատման փաստաթղթերը հաստատելուց առաջ գրություններ են ուղարկում գերատեսչություններ, այդ թվում՝Քաղաքաշինության կոմիտե, տեսչական մարմին, վարչական շրջաններ։ Նշված կառույցներից դրական պատասխան ստանալուց հետո միայնշինթույլտվություն են տալիս։ Իսկ թե ինչպես է ստացվում, որ գազալցակայանները մոտ են գտնվում այլ սուբյեկտների շինություններին, քաղաքապետարանից նշեցին, թե անվտանգության հարցերի համար համայնքը պատասխանատու չէ։'
    ]
  }

];

export const epilogueTitle = 'Գազալցակայաններ, որոնց նախկին սեփականատերերը պաշտոնյաներ են եղել';