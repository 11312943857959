import React from 'react';
import PropTypes from 'prop-types';

function Container ({ children, containerWrapperClass, containerClass }) {
  return (
    <div className={`${containerWrapperClass} px-5 md:px-10 lg:px-20`}>
      <div className={`${containerClass} grid grid-cols-2 md:grid-cols-8 lg:grid-cols-12 gap-x-5 md:gap-x-6 lg:mx-auto lg:max-w-screen-xl`}>
        {children}
      </div>
    </div>
  );
}

Container.defaultProps = {
  containerWrapperClass: '',
  containerClass: '',
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  containerWrapperClass: PropTypes.string,
  containerClass: PropTypes.string,
};

export default Container;