import React from 'react';
import Container from '../../shared/Container';
import H1 from '../../shared/Headings/H1';
import { prologueContent } from '../../../consts/prologueContent';
import H2 from '../../shared/Headings/H2';
import parse from 'html-react-parser';

function Prologue() {
  return(
    <section>
      <Container>
        <H1 name={prologueContent.title} className='mt-4.5 md:mt-49.5 text-blue-750 text-center font-primary-medium col-span-full' />
        <picture className='col-span-full xl:col-span-8 xl:col-start-3 xl:col-end-11 mt-20 md:mt-24'>
          <source type='image/webp' srcSet={prologueContent.src.primary} />
          <source type='image/jpeg' srcSet={prologueContent.src.secondary} />
          <img src={prologueContent.src.secondary} alt={prologueContent.alt} />
        </picture>
        <div className='col-span-full xl:col-span-8 xl:col-start-3 xl:col-end-11 text_s_light xl:font-primary-light xl:text-lg xl:tracking-normal'>
          {
            prologueContent.paragraphs.map((item, index) => (
              <p key={index} className='mt-8 text-black-750'>{parse(item)}</p>
            ))
          }
        </div>
        <H2 name={prologueContent.subtitle} className='text-blue-750 col-span-full xl:col-start-3 xl:col-end-11 xl:col-span-10 text-center mt-24' />
      </Container>
    </section>
  );
}

export default Prologue;