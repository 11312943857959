import parse from 'html-react-parser';
import PropTypes from 'prop-types';

function EpilogueItemWithMediaFile({ paragraphs, images, className }){
  return(
    <div className={`col-span-full xl:col-start-3 xl:col-end-11 ${className}`}>
      {
        paragraphs.map((text, index) => (
          <p className={`text_s_light text-black-750 lg:font-primary-light lg:text-lg lg:tracking-normal col-span-full col-start-1 ${index !== 0 ? 'mt-6 xl:mt-8' : 'mt-24 md:mt-32'}`} key={index}>{parse(text)}</p>
        ))
      }
      {
        images?.map((image, index) => (
          <div className='mt-16' key={index}>
            <picture>
              <source type='image/webp' srcSet={image.src.primary} />
              <source type='image/png' srcSet={image.src.secondary} />
              <img src={image.src.secondary} alt={image.alt} />
            </picture>
            {image.subtitle ? <p className='text_xs_regular text-black-750 mt-6'>{image.subtitle}</p> : null}
          </div>
        ))
      }
    </div>
  );
}

EpilogueItemWithMediaFile.defaultProps = {
  className: '',
  images: []
};

EpilogueItemWithMediaFile.propTypes = {
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.shape({
      primary: PropTypes.string,
      secondary: PropTypes.string
    }),
    alt: PropTypes.string
  })),
  className: PropTypes.string
};

export default EpilogueItemWithMediaFile;