import { useState, useEffect } from 'react';

export default function useDeviceDetect() {
  const [device, setDevice] = useState({
    isMobile: false,
    isTablet: false,
  });

  useEffect(() => {
    function handleResize() {
      setDevice( {
        isMobile: window.innerWidth < 768,
        isTablet: window.innerWidth >= 768 && window.innerWidth < 1024,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return device;
}
