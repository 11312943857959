import React from 'react';
import Container from '../../shared/Container';

function Header(){
  return(
    <header className='bg-white z-50 fixed inset-x-0 top-0 w-full h-75 shadow-headerShadow'>
      <Container>
        <div className='relative col-span-full flex flex-row items-center'>
          <div className='absolute top-0 left-0'>
            <img src='./images/Header/logo.svg' alt='logo' className='w-85 h-85 md:w-100 md:h-100' />
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;