import React from 'react';
import PropTypes from 'prop-types';

function H1({ name, className }){
  return(
    <h1 className={`heading_1 ${className}`}>{name}</h1>
  );
}

H1.defaultProps = {
  className: ''
};

H1.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default H1;