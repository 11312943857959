import React, { useEffect, useRef, useState, useCallback } from 'react';
import Container from '../../Container';
import H2 from '../../Headings/H2';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styles from './index.module.scss';
import useDeviceDetect from './../../../../hooks/useDeviceDetect';

function MobileSticky(props){
  const {
    className,
    texts,
    images,
    alt,
    title,
    timeoutImage,
    width,
    count
  } = props;

  let sum = 0;

  const parentRef = useRef(null);

  const sectionRef = useRef(null);

  const textRef = useRef([]);

  const articleRef = useRef([]);

  const [viewport, setViewport] = useState(0);

  const [showTimeoutImage, setShowTimeoutImage] = useState(false);

  const { isMobile } = useDeviceDetect();

  const autoAnimation = useCallback(
    () => {
      let element = sectionRef?.current?.getBoundingClientRect();
      if(element?.y + element?.height <= window?.innerHeight) {
        for(let i = 0; i < articleRef?.current?.length; i++){
          sum += articleRef?.current[i]?.clientHeight;
        }

        if(sum <= parentRef?.current?.clientHeight){
          for(let i = 1; i < images.length; i++){
            setViewport(i);
          }
        }
      }
    },[]);

  useEffect(() => {
    window.document.addEventListener('scroll', autoAnimation);

    return () => {
      window.document.removeEventListener('scroll', autoAnimation);
    };
  },[autoAnimation]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  },[]);

  useEffect(() => {
    if(viewport === 1 && timeoutImage === 2){
      setTimeout(() => {
        setShowTimeoutImage(true);
      }, 1000);
    } else if(viewport === 2 && timeoutImage === 3){
      setTimeout(() => {
        setShowTimeoutImage(true);
      }, 1000);
    } else if(viewport < 2){
      setShowTimeoutImage(false);
    }
  },[viewport]);

  const onScroll = () => {
    for(let i = 0; i < textRef.current.length; i++){
      if(textRef?.current[0].getBoundingClientRect().top === textRef.current[i].offsetParent.getBoundingClientRect().top){
        setViewport(0);
        break;
      }

      if(textRef?.current[i]?.getBoundingClientRect().bottom - textRef?.current[i]?.offsetHeight < textRef.current[i].offsetParent.getBoundingClientRect().bottom){
        setViewport(i);
      }
    }
  };

  return (
    <section className={`${className} w-full`}>
      <Container>
        <H2 className={`col-span-full text-blue-750 pb-60 md:pb-20 ${count === 0 ? 'mt-28 md:mt-36' : ''}`} name={title} />
        <div ref={sectionRef} className='relative col-span-full mx-auto' style={{ width: !isMobile ? width : 'auto' }}>
          <img src={images[0]}
            alt={alt}
            className='object-cover mb-10'
          />
          <img
            src={images[1]}
            alt={alt}
            className={`object-cover absolute top-0 left-0 ${viewport === 1 ? 'animate-showImage' : viewport > 1 && images[2] ? 'animate-hideImage' : ''} ${viewport !== 0 ? 'opacity-1' : 'opacity-0'}`}
          />
          {
            images[2]
            &&
            <img
              src={images[2]}
              alt={alt}
              className={`object-cover absolute top-0 left-0 ${viewport === 2 ? 'animate-showImage' : viewport !== 3 && viewport !== 4 ? 'animate-hideImage' : ''} ${viewport !== 1 ? 'opacity-1' : 'opacity-0'}`}
            />
          }

          {
            showTimeoutImage
              ?
              <img
                src={images[timeoutImage]}
                alt={alt}
                className={`object-cover absolute top-0 left-0 animate-showImage ${viewport !== 0 ? 'opacity-1' : 'opacity-0'}`}
              />
              :
              <img
                src={images[timeoutImage]}
                alt={alt}
                className={`object-cover absolute top-0 left-0 animate-hideImage ${viewport !== 0 ? 'opacity-1' : 'opacity-0'}`}
              />
          }
        </div>
        <div className={`bg-white p-15 md:p-5 shadow-textBlockShadow col-span-full ${styles.text}`}>
          <div className='h-270 md:h-48 relative overflow-auto scroll-smooth' onScroll={onScroll} ref={parentRef}>
            {texts.map((text, index) => (
              <div key={index} ref={el => articleRef.current[index] = el}>
                { index !== 0 && <div className="h-25" /> }
                <p className="text_s_light text-black-650" ref={el => textRef.current[index] = el}>{parse(text)}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}

MobileSticky.defaultProps = {
  className: '',
  timeoutImage: 0,
  width: 'auto',
};

MobileSticky.propTypes = {
  className: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  alt: PropTypes.string.isRequired,
  timeoutImage: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};

export default MobileSticky;