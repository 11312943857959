export const prologueContent = {
  title: 'Երևանի 23 գազալցակայանից 16-ն աշխատում է քաղաքաշինական խախտումներով',
  subtitle: 'Պաշտոնյաներ, որոնք գազալցակայանների սեփականատերեր են',
  src: {
    primary: './images/Prologue/gas_station.webp',
    secondary: './images/Prologue/gas_station.jpg'
  },
  alt: 'gas_station',
  paragraphs: [
    'Երևանի 23 գազալցակայաններից 16-ը քաղաքաշինական և անվտանգության <a href="https://www.arlis.am/DocumentView.aspx?DocID=65113" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">նորմերի</a> խախտումներով է աշխատում։ Այդ խախտումները բացահայտող և վերահսկող Տեխնիկական և հրդեհային անվտանգության (ՔՏՀԱ) տեսչական մարմինը վերջին երկու տարիներին ԱԳԼՃԿ-ներում որևէ տարեկան պլանային ստուգում չի իրականացրել։ (Երևանում գործող գազալցակայանների տվյալները ձեռք ենք բերել Երևանի քաղաքապետարանից՝ 2021 թվականի հոկտեմբերին):',
    'Ուշագրավ է, որ խախտումներով աշխատող որոշ գազալցակայանների սեփականատերեր նախկին և ներկա պաշտոնյաներ են, որոնք հանրային առողջությունն ու անվտանգությունը ստորադասել են իրենց գործարար շահին։ Երևանի 23 գազալցակայաններից 15-ը պատկանում է ներկա և նախկին պաշտոնյաների։ Վերջին տարիներին￼պաշտոնյաներից ոմանք հրաժարվել են իրենց  բաժնետոմսերից։'
  ]
};