import Container from '../../shared/Container';

function Footer(){
  return(
    <footer className='bg-gray-80 text-black-650 py-6'>
      <Container>
        <p className='text_s_regular col-span-full lg:col-start-4 lg:col-end-10 text-center'>Հետք Մեդիա Գործարանի հետաքննող թիմ</p>
        <p className='text_xs_regular col-span-full text-center mt-5.5 lg:mt-0 lg:text-right lg:col-start-10 lg:col-end-13 lg:flex lg:justify-end lg:items-center'>© 2022 Hetq Media Factory</p>
      </Container>
    </footer>
  );
}

export default Footer;