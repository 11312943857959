export const stickyContent = [
  {
    title: '«Կելլի և Վիլիամս» ՍՊԸ',
    alt: 'artashes_tumanyan',
    texts: [
      '<a href="http://despan.am/hy/republic-of-armenia-ambassadors/32/" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Արտաշես Թումանյանը</a> վարչապետի խորհրդական է նշանակվել 2021 թվականին։ Մինչ այդ՝ 2015-2021 թվականներին եղել է Իրանում Հայաստանի արտակարգ և լիազոր դեսպանը։ <span style="fontFamily:primary-semibold">Արտաշես Թումանյանը</span> 1988 թվականից զբաղեցրել է տարբեր պաշտոններ. նախագահի աշխատակազմի ղեկավար, հարկային տեսչության պետ, Գերագույն խորհրդի պատգամավոր, Գերագույն խորհրդի նախագահի տեղակալ։',
      'Պաշտոնյայի որդին՝ Սամվել Թումանյանը, <span style="fontFamily:primary-semibold">«Կելլի և Վիլիամս» ՍՊԸ</span>-ի միանձնյա սեփականատերն է։ Ընկերությունը Շիրազի 43 հասցեում գազալցակայան է շահագործում։',
      'Լցակայանի սպասասրահի և լիցքավորման սարքերի միջև հեռավորությունը, ըստ քաղաքաշինական չափորոշիչների, պետք է լինի նվազագույնը 20մ։ Այս լցակայանում այն 12մ է։ Այցելեցինք գազալցակայան՝խախտումների մասին պարզաբանում ստանալու տնօրենից, սակայն նա տեղում չէր։ Մինչև նյութը հրապարակելն այդպես էլ հնարավոր տնօրենից պարզաբանում ստանալ:'
    ],
    images: ['./images/Sticky/Kelli/variant1.png', './images/Sticky/Kelli/variant2.png', './images/Sticky/Kelli/variant2.2.png'],
    maxWidth: 664,
    colSpan: [6,13],
    timeoutImage: 2
  },
  {
    title: '«Մուլտի Գազ» ՍՊԸ',
    alt: 'gagik_tsarukyan',
    texts: [
      '<span style="fontFamily:primary-semibold">Գագիկ Ծառուկյանն</span> Ազգային ժողովի նախկին պատգամավոր է, 2011-2015 թթ. եղել է Ազգային անվտանգության խորհրդի անդամ։ Հայաստանի Ազգային Օլիմպիական կոմիտեի նախագահն է 2005-ից։ 2004-ին հիմնադրել է «Բարգավաճ Հայաստան» կուսակցությունը և առ այսօր կուսակցության նախագահն է։',
      '<span style="fontFamily:primary-semibold">Գագիկ Ծառուկյանը</span> 2002-ից 65.8% բաժնեմաս ունի «Մուլտի Գրուպ» կոնցեռնում։ Կոնցեռնի 34.2% բաժնետերը նրա հորեղբոր որդին է՝ Սամվել Ծառուկյանը։ Ընկերության գլխավոր տնօրենը <span style="fontFamily:primary-semibold">Սեդրակ Առուստամյանն</span> է։',
      '«Մուլտի գրուպ» կոնցեռնին է պատկանում <span style="fontFamily:primary-semibold">«Մուլտի գազ» ՍՊԸ</span>-ի 100% բաժնեմասը։ <span style="fontFamily:primary-semibold">«Մուլտի Գազ» ՍՊԸ</span>-ն Երևանում ունի չորս գազալցակայան՝ Գյուրջյան և Ռուբինյանց փողոցներում, Թբիլիսյան և Նուբարաշենի խճուղիներում։',
      'Այս ընկերությունը համատեղությամբ շահագործում է Նուբարաշենի խճուղում գործող գազալցակայանը «Ավտոգազ» ՍՊԸ-ի հետ, որի 100% բաժնեմասը պատկանում է «Գազպրոմ Արմենիա» ՓԲԸ-ին։<br><br>Գազալցակայանում պահպանված չէ օպերատորական շինությունից մինչև տեխնոլոգիական սարքավորումները սահմանված 9մ հեռավորությունը։ Սպասասրահի և լիցքավորման սարքերի միջև հեռավորությունը նախատեսված 20-ի փոխարեն 7մ է։<br><br>Իսկ Ռուբինյանց փողոցի գազալցակայանի ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքեր ու շինություններ հեռավորությունը 30մ է՝ սահմանված 50 մ-ի փոխարեն։ Արբանյակից արված լուսանկարներից պարզ դարձավ, որ գազալցակայանի հարևանությամբ գտնվող շենքերը կառուցվել են ավելի ուշ, քան գազալցակայանը։',
    ],
    images: ['./images/Sticky/Gas/variant1.png', './images/Sticky/Gas/variant2.png', './images/Sticky/Gas/variant3.png', './images/Sticky/Gas/variant2.2.png'],
    maxWidth: 450,
    colSpan: [7,12],
    timeoutImage: 2
  },
  {
    title: '«Մուլտի-Լեոն» ՍՊԸ',
    alt: 'robert_kocharyan',
    texts: [
      '<span style="fontFamily:primary-semibold">Ռոբերտ Քոչարյանը</span> 1998-2008 թվականներին եղել է Հայաստանի նախագահը։ Այս պահին Քոչարյանը «Հայաստան» դաշինքի առաջնորդն է, որը համանուն խմբակցություն ունի ԱԺ-ում։ Դաշինքը ստեղծվել է 2021 թվականին՝ խորհրդարանական ընտրություններին ընդառաջ։',
      'Քոչարյանի որդուն՝ Սեդրակ Քոչարյանին է պատկանում «Սթրիմ ֆորս» ՍՊԸ-ն, որն իր հերթին «Փաբլիք սըփորթ սերվիսիս լիմիթեդ» ՓԲԸ-ի 100% բաժնետերն է։ 2010-ին նշված ՓԲԸ-ի բաժնետոմսերի 75%-ը պատկանում էր «Սթրիմ ֆորս» ՍՊԸ-ին, իսկ 25%-ը՝ Գագիկ Ծառուկյանի երբեմնի գործընկեր Սուրեն Ավագյանին։ Վերջինս մեղադրյալի կարգավիճակ ունի «Հյուսիս-հարավ» ճանապարհի կառուցման չարաշահումների դեպքով հարուցված <a href="https://www.prosecutor.am/am/mo/8291/" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45" target="_blank">քրեական գործում</a>:',
      '«Փաբլիք սըփորթ սերվիսիս լիմիթեդ» ՓԲԸ-ն և «Մուլտի գրուպ» կոնցեռնը հավասարապես (50-50%) տնօրինում են «Մուլտի-Լեոն» ՍՊԸ-ի բաժնեմասերը։ «Մուլտի–Լեոն» ՍՊ ընկերությունը Երևանում չորս գազալցակայան ունի։ Դրանք գտնվում են հետեւյալ հասցեներում՝ Իսակովի պողոտա, Շիրազ-Ջանիբեկյան փողոցների խաչմերուկ, Բագրատունյաց 91 և Շիրակի 1/4:',
      'Բագրատունյաց փողոցի գազալցակայանի ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքեր ու շինություններ հեռավորությունը սահմանված 50 մ-ի փոխարեն 45 մ է։<br><br>«Մուլտի-Լեոն» ՍՊԸ-ի՝ Շիրազ-Ջանիբեկյան փողոցների խաչմերուկում գտնվող գազալցակայանում սպասասրահի և լիցքավորման սարքերի հեռավորությունը սահմանված 20մ-ի փոխարեն 18մ է, իսկ Շիրակի 1/4 հասցեում գտնվող գազալցակայանում 20մ-ի փոխարեն 17մ է։<br><br>Հաշվի առնելով, որ «Մուլտի գրուպ» կոնցեռնը և՛ «Մուլտի գազ» ՍՊԸ-ի (100%) և՛ «Մուլտի-Լեոն» ՍՊԸ-ի (50%) բաժնետերն է, փորձեցինք վերը նշված խախտումների վերաբերյալ պարզաբանում ստանալ հենց «Մուլտի գրուպից»։ Շաբաթներ շարունակ կոնցեռնի տնօրենից պարզաբանում ստանալ չհաջողվեց։'
    ],
    maxWidth: 664,
    colSpan: [6,13],
    images: ['./images/Sticky/Leon/variant1.png', './images/Sticky/Leon/variant2.png', './images/Sticky/Leon/variant3.png']
  },
  {
    title: '«Գուրգեն Դավթյան» ՍՊԸ',
    alt: 'taron_margaryan',
    texts: [
      '<a href="http://parliament.am/deputies.php?sel=details&ID=1438&lang=arm" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Տարոն Մարգարյանը</a> ԱԺ պատգամավոր է՝ «Պատիվ ունեմ» դաշինքից։ 2011-2018 թթ. եղել է Երևանի քաղաքապետ։ Նա 2001 թվականից զբաղեցրել է տարբեր պաշտոններ. առաջատար մասնագետ Կադաստրի  կոմիտեում, այնուհետև՝ բաժնի պետ, Բնապահպանության նախարարության կենսառեսուրսների կառավարման գործակալության պետի տեղակալ, Ավան համայնքի ղեկավար։',
      'Պաշտոնյայի հորաքույրը՝ Անահիտ Մարգարյանը, 2011-ից <span style="fontFamily:primary-semibold">«Գուրգեն Դավթյան» ՍՊԸ</span>-ի միանձնյա սեփականատերն է, որը գազալցակայան է շահագործում Գյուրջյան 14/1 հասցեում։',
      'Այս գազալցակայանում նկատել ենք 2 կուտակիչ։ Դրանցից մեկի հեռավորությունը լիցքավորման սարքերից 17մ է՝ սահմանված 20-ի փոխարեն, իսկ կուտակիչներից մինչև գազալցակայանի ցանկապատը սահմանված 5մ հեռավորությունը պահպանված չէ. դրանք անմիջապես ցանկապատի տակ են։ Սպասարահից մինչև լիցքավորման սարքերը սահմանված 20մ հեռավորությունը նույնպես պահպանված չէ, այստեղ 18 մ է: Պարզաբանում ստանալու մեր փորձերն այս դեպքում էլ ապարդյուն անցան։'
    ],
    images: ['./images/Sticky/Gurgen/variant1.png', './images/Sticky/Gurgen/variant2.png', './images/Sticky/Gurgen/variant2.2.png'],
    maxWidth: 664,
    colSpan: [6,13],
    timeoutImage: 2
  },
  {
    title: '«Ավտոստոպ» ՍՊԸ',
    alt: 'mher_sedrakyan',
    texts: [
      '<a href="http://parliament.am/deputies.php?sel=details&ID=1020&lang=arm" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Մհեր Սեդրակյանը</a> ԱԺ պատգամավոր է եղել 1995 - 1999 թթ., որից հետո մինչև 2005 թվականը՝ Էրեբունի համայնքի ղեկավար։ 2005 - 2017թթ.՝ կրկին ԱԺ պատգամավոր։',
      '2009-ից մինչ օրս <span style="fontFamily:primary-semibold">«Ավտոստոպ» ՍՊԸ</span>-ի միանձնյա սեփականատերն է, որը գազալցակայան է շահագործում է Սասունցի Դավիթ 89 հասցեում։',
      'Գազալցակայանի տեխնոլոգիական սարքավորումները բազմամարդ վայրերից պետք է առնվազն 100 մ հեռավորության վրա լինեն, սակայն գազալցակայանի հարևանությամբ է տեղակայված Էրեբունի վարչական շրջանի աշխատակազմի շենքը, N17 դպրոցը, իսկ անմիջապես ետնամասում՝ «Վարդավառի» այգին։<br><br>Մհեր Սեդրակյանին հանդիպեցինք գազալցակայանում։ Նա պնդեց, որ իր գազալցակայանում խախտումներ չկան. «Եթե լինեին, հիմա վաղուց փակած կլինեին»։ Երբ մանրամասնեցինք խախումների բնույթը, Սեդրակյանն առաջարկեց հեռախոսահամար փոխանցել չափորոշիչներից տեղյակ իրենց աշխատակցին, որը կտա մեր հարցերի պատասխանը։ «Ավտոստոպ» ՍՊԸ-ից մեզ հետ այդպես էլ կապ չհաստատեցին։'
    ],
    maxWidth: 430,
    colSpan: [7,12],
    images: ['./images/Sticky/Avtostop/variant1.png', './images/Sticky/Avtostop/variant2.png']
  },
  {
    title: '«Խճաքար-Մուսա» ՍՊԸ',
    alt: 'hrant_davtyan',
    texts: [
      '<a href="http://parliament.am/deputies.php?sel=details&ID=1107&lang=arm" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Հրանտ Դավթյանը</a>  ԱԺ նախկին  պատգամավոր է, «Բարգավաճ Հայաստան» կուսակցության անդամ։ «Գրանտ-գրուպ» իրավաբանական անձանց միության նախագահն է։ Նա Դուբայում իր ունեցվածքը չհայտարարագրելու համար <a href="https://hetq.am/hy/article/121349" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">մեղադրյալի</a> կարգավիճակ էր ստացել:',
      'Դավթյանը <span style="fontFamily:primary-semibold">«Խճաքար-Մուսա» ՍՊԸ</span>-ի միանձնյա սեփականատերն է, որը գազալցակայան է շահագործում Տիչինայի 140 հասցեում՝ Բաբաջանյան-Աշտարակ նոր կառուցված ավտոճանապարհի կամրջի հարևանությամբ։',
      'Գազալցակայանի ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքերն ու շինությունները սահմանված 50մ հեռավորությունը պահպանված չէ։ Արբանյակից արված լուսանկարներից պարզ դարձավ, որ հարևանությամբ գտնվող շենքերը կառուցվել են գազալցակայանի շահագործվելուց հետո։ Սպասարահի և լիցքավորման սարքերի միջև ընկած հեռավորությունը 20մ-ի փոխարեն 17մ է։ Գազալցակայանի սպասասրահից մինչև իրեն պատկանող շենքի ամենավերջին կետը մոտ 25մ է, ինչը նշանակում է, որ սպասասրահից մինչև կուտակիչները սահմանված 35մ և սպասասրահից մինչև ճնշակայանը սահմանված 25մ հեռավորությունները պահպանված չեն:<br><br>Գազալցակայանում անտարբեր արձագանքեցին խախտումների մասին մեր հարցերին. աշխատակիցը հայտնեց, որ տնօրինությունից մարդ չկա։ Կոնտակտային տվյալներ փոխանցեցինք, բայց մեզ հետ կապ չհաստատեցին։'
    ],
    maxWidth: 430,
    colSpan: [7,12],
    images: ['./images/Sticky/Musa/variant1.png', './images/Sticky/Musa/variant2.png']
  },
  {
    title: '«Գաօմ Գրուպ» ՓԲԸ',
    alt: 'ohan_muradyan',
    texts: [
      '<span style="fontFamily:primary-semibold">Օհան Մուրադյանը</span> մինչև 2016 թվականը բարձր պաշտոններ է զբաղեցրել դատախազությունում՝ ընդհուպ ֆինանսատնտեսական վարչության պետի պաշտոնակատար։',
      'Նա «Դավիթ երթուղային տաքսի» ՓԲԸ–ի հիմնադիրն է, որն էլ 2008 թվականին վերակազմակերպման ձևով նոր ընկերություն է հիմնել՝ <span style="fontFamily:primary-semibold">«Գաօմ Գրուպ» ՓԲԸ</span>-ն։ 2011-ի դրությամբ՝ ՓԲԸ-ի 100 % բաժնեմասի սեփականատերը Օհան Մուրադյանն է։ Ընկերությունը գազալցակայան է շահագործում Դավթաշեն 3-րդ թաղամաս, 19/2 հասցեում։',
      'Գազալցակայանի ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքեր ու շինություններ հեռավորությունը սահմանված 50 մ-ի փոխարեն 43 մ է։ Գազալցակայանի սպասասրահից մինչև իրեն պատկանող շենքի ամենավերջին կետը 33մ է, այսինքն՝ սպասասրահից մինչև կուտակիչները սահմանված 35մ հեռավորությունը պահպանված չէ:<br><br>Մեզ հետ հեռախոսազրույցում ընկերության տնօրեն ներկայացած Գրիգորը պնդում էր, թեգազալցակայանում քաղաքաշինական խախտումներ չկան, իսկ հարևանությամբ գտնվող շինությունները կառուցվել են գազալցակայանի շահագործումից հետո։ Մինչդեռ արբանյակից արված լուսանկարները հակառակի մասին են փաստում։ Հարևանությամբ գտնվող շենքը 2000թ.-ի արբանյակային լուսանկարում երևում է, իսկ գազալցակայանի շենքը չկա։ Տնօրենը չցանկացավ լսել խախտումների մասին մեր մյուս հարցերը՝ առաջարկելով դիմել պատկան մարմիններին։'
    ],
    maxWidth: 442,
    colSpan: [7,12],
    images: ['./images/Sticky/Gaom/variant1.png', './images/Sticky/Gaom/variant2.png']
  },
  {
    title: '«Քար գազ Սերվիս» ՍՊԸ',
    alt: 'hovik_abovyan',
    texts: [
      '<a href="http://www.parliament.am/deputies.php?sel=details&ID=105&lang=arm" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">Հովիկ Աբովյանը</a> ԱԺ նախկին պատգամավոր է, 1993 թվականից զբաղեցրել է տարբեր պետական պաշտոններ. Նոյեմբերյանի հարկային տեսչության տեսուչ, Բագրատաշենի մաքսային տեսուչ, մաքսակետի պետի տեղակալ,Բավրայի մաքսակետի պետ, Գոգավան-Պրիվոլնոյեի մաքսակետի պետ։ Աբովյանի զբաղեցրած վերջին պաշտոնը Տավուշի մարզպետն է եղել։',
      'Նախկին պաշտոնյան <span style="fontFamily:primary-semibold">«Քար գազ Սերվիս» ՍՊԸ</span>-ի 50% բաժնետերն է, մյուս 50%-ի բաժնետերը <a href="https://hetq.am/hy/article/89028" target="_blank" style="color:#1E4B6B; textDecoration:underline; textDecorationColor:#333F45">«Արմենիան հելիքոփթերս» ՍՊԸ-ի միանձնյա սեփականատեր<a/> Կարեն Վարդանյանն է: Սեփականատերերը այն ձեռք են բերել 2016 թվականին նախկին պատգամավոր Ռուբեն Գևորգյանի (Ծաղիկ Ռուբո) դուստր Արմենուհի Գևորգյանից, որը 2012-ից «Քար գազ Սերվիս» ՍՊԸ֊ի միանձնյա սեփականատերն էր։ «Քար գազ Սերվիս» ՍՊԸ-ն Սասնա Ծռեր 1/5 հասցեում գազալցակայան է շահագործում։',
      'Գազալցակայանի ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքերն ու շինությունները հեռավորությունը 50մ-ի փոխարեն 15մ է։ Կուտակիչների տեղամասից մինչև գազալցակայանի տարածքում գտնվող այլ նշանակություն ունեցող շենքերի միջև հեռավորությունը սահմանված 30մ-ի փոխարեն 5մ է։<br><br>Խախտումների վերաբերյալ մեր հարցերին գազալցակայանի տնօրենի ուղղորդմամբ պատասխանեց Հովիկ Աբովյանի որդին՝ Աշոտ Աբովյանը։ Վերջինս ասաց, որ գազալցակայանը ձեռք բերելուց հետո են իմացել դրա քաղաքաշինական խախտումների մասին, բայց քանի որ այն հնարավոր չէ վերացնել, անվտանգությունից ելնելով՝ կուտակիչի շուրջ  բետոնե պատնեշ են կառուցել։ Աշոտ Աբովյանը նաև ասաց, որ հարակից շինությունը գազալցակայանի շահագործումից հետո է կառուցվել, ինչը հնարավոր չէր ստուգել, քանի որ գազալցակայանի և հարակից շինության շինարարական աշխատանքների ժամանակահատվածում արված արբանյակային լուսանկարներ չկային։'
    ],
    maxWidth: 465,
    colSpan: [7,12],
    images: ['./images/Sticky/Qar/variant1.png', './images/Sticky/Qar/variant2.png']
  },
  {
    title: '«Օկտան դպ» ՍՊԸ',
    alt: 'ervand_zakharyan',
    texts: [
      '<span style="fontFamily:primary-semibold">Երվանդ Զախարյանը</span> 1992-ից տարբեր պաշտոններ է զբաղեցրել։ Եղել է էներգետիկայի նախարարի տեղակալ, ՊԵԿ նախագահ, տրանսպորտի և կապի նախարար, Երևանի քաղաքապետ, նախագահի խորհրդական, կադաստրի կոմիտեի նախագահ, էներգետիկայի և բնական պաշարների նախարար։',
      'Նախկին պաշտոնյայի զավակները՝ Արտակ (80%), Լիլիթ (10%) և Գայանե (10%) Զախարյանները <span style="fontFamily:primary-semibold">«Արգալի գրուպ» ՍՊԸ</span>-ի բաժնետերերն են։',
      '«Արգալի գրուպ» ՍՊԸ-ին է պատկանում <span style="fontFamily:primary-semibold">«Օկտան դպ» ՍՊԸ</span>-ի 40% բաժնեմասը։ Մյուս բաժնետերերը Արթուր Ավետիսյանն (50%) ու Գագիկ Զախարյանն (10%) են։ Վերջինս Երվանդ Զախարյանի եղբորորդին է։ «Օկտան դպ» ՍՊԸ-ն գազալցակայան է շահագործում  Գրիբոյեդովի 48 հասցեում։',
      'Գազալցակայանի ճնշակայանից մինչև այլ սուբյեկտի պատկանող հարակից շենքերն ու շինությունները 42մ է՝ սահմանված 50մ հեռավորության փոխարեն։ Արբանյակից արված լուսանկարներից պարզ դարձավ, որ գազալցակայանի հարևանությամբ գտնվող շենքերը կառուցվել են լցակայանի շահագործումից հետո։ Սպասարահի և լիցքավորման սարքերի միջև հեռավորությունը սահմանված 20մ-ի փոխարեն 14մ է։ Գազալցակայանից խախտումների վերաբերյալ պարզաբանում ստանալ չհաջողվեց։'
    ],
    images: ['./images/Sticky/Oktan/variant1.png', './images/Sticky/Oktan/variant2.png', './images/Sticky/Oktan/variant3.png', './images/Sticky/Oktan/variant3.2.png'],
    maxWidth: 600,
    colSpan: [6,13],
    timeoutImage: 3
  },
];